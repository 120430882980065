<template>
  <div>
    <section class="image-side">
      <div class="image">
        <VImage
          alt="imagem de mulher com tablet"
          class="image-side__content__img"
          :src="contentImageSide.image"
          quality="100"
          :position="side"
          :class="side == 'right' ? 'image-side__content__img--right' : ''"
        />
      </div>
      <div class="container">
        <div class="row">
          <div
            :class="side == 'right' ? '' : 'offset-md-6'"
            class="col-12 col-md-6 d-flex justify-content-center flex-column"
          >
            <div class="image-side__content">
              <h3 class="image-side__content__about__title font-bold">
                {{ $t(contentImageSide.title.content) }}
              </h3>
              <p class="image-side__content__about__text mt-4">
                {{ $t(contentImageSide.content.text) }}
              </p>
              <p v-if="contentImageSide.footer" class="image-side__content__about__footer font-black">
                {{ $t(contentImageSide.footer) }}
              </p>
              <NuxtLink v-if="contentImageSide.button" :to="contentImageSide.button.link">
                <p class="image-side__content__about__link font-bold">
                  <span class="font-bold">
                    {{ $t(contentImageSide.button.content) }}
                    <span v-html="arrow"></span>
                  </span>
                </p>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { PropType } from 'nuxt/dist/app/compat/capi'

enum Side {
  Left = 'left',
  Right = 'right',
}

interface IContent {
  image: string
  title: {
    color?: string
    content: string
  }
  footer?: string
  content: {
    text: string
  }
  button?: {
    color: string
    content: string
    link?: string
  }
}

export default {
  props: {
    contentImageSide: {
      type: Object as PropType<IContent>,
      required: true,
    },

    side: {
      type: String,
      default: Side.Left,
    },

    contentTextFont: {
      type: String,
      default: 'normal normal normal 16px/24px Montserrat;',
    },

    contentTextPaddingLeft: {
      type: String,
      default: '0px;',
    },
  },
  data() {
    return {
      arrow: `<svg style="align-self: center;" xmlns="http://www.w3.org/2000/svg" width="14.002" height="13.647" viewBox="0 0 14.002 13.647">
                <path id="Caminho_816" data-name="Caminho 816"
                d="M5.953-11.909a.745.745,0,0,0,.013,1.072L9.731-7.25H.75A.748.748,0,0,0,0-6.5v1a.748.748,0,0,0,.75.75H9.731L5.966-1.163A.751.751,0,0,0,5.953-.091L6.647.6A.747.747,0,0,0,7.706.6l6.075-6.075a.747.747,0,0,0,0-1.059L7.706-12.6a.747.747,0,0,0-1.059,0Z"
                transform="translate(0 12.823)" fill="#FB9335" />
                </svg>`,
    }
  },

  computed: {
    colorTitle() {
      return this.contentImageSide.title.color
    },
  },
}
</script>

<style lang="scss" scoped>
.image-side {
  &__content {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__img {
      height: 500px;

      &--right {
        @media (max-width: $mobile) {
          left: 0% !important;
        }
      }
    }

    &__about {
      gap: 26px;
      width: 50%;
      box-sizing: border-box;
      padding: 20px;

      &__title {
        font: normal normal 900 40px/49px Montserrat;
        letter-spacing: 0px;
        color: v-bind(colorTitle);
      }

      &__text {
        text-align: left;
        font: normal normal normal 16px/24px Montserrat;
        letter-spacing: 0px;
        color: #323232;
        width: 80%;
      }

      &__footer {
        font: normal normal 900 16px/24px Montserrat;
        letter-spacing: 0px;
        color: #707070;
        margin-top: 20px;
      }

      &__link {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #fb9335 !important;
        padding-top: 20px;

        span {
          font: normal normal 900 16px/24px Montserrat;
          letter-spacing: 0px;
          color: #fb9335;
          display: flex;
          align-items: center;
          cursor: pointer;
          display: flex;
          gap: 8px;
          align-self: center;
          margin: 5px;

          &:hover {
            color: #960b38;
          }

          &:hover svg > path {
            fill: #960b38;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1148px) {
  .image-side__content__about {
    padding: 60px 20px;
  }
}

@media screen and (max-width: $mobile) {
  .image-side__content {
    flex-direction: column;
    height: 100%;
    padding-bottom: 25px;

    &__img {
      width: 100% !important;
      height: 265px !important;
      padding: 0 !important;
      position: relative !important;
      right: 0 !important;
    }

    &__about {
      width: 100%;

      &__title {
        font: normal normal 900 25px/30px Montserrat;
        margin-top: 20px;
      }

      &__text,
      &__footer {
        font: v-bind(contentTextFont);
        padding-left: v-bind(contentTextPaddingLeft);
        width: 100%;
      }

      &__link {
        font: normal normal 900 13px/24px Montserrat;
      }
    }
  }
}
</style>
